import {
  FaTextHeight,
  FaImage,
  FaVideo,
  FaFileAlt,
  FaMapMarkerAlt,
} from "react-icons/fa"
import { BsFillReplyFill } from "react-icons/bs"
import { PiArrowSquareInLight } from "react-icons/pi"
import { MdCall, MdOutlineContentCopy } from "react-icons/md"
import Img1 from "../images/profile.png"
import Img2 from "../images/profile_2.png"

export const template = [
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
  {
    id: 1,
    title: "template1",
    description:
      "Entrepreneurship Competition Winner First place in a startup competition Entrepreneurship Competition Winner First place in a startup competition",
  },
]

export const RecentUpdate = [
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
  {
    id: 1,
    template_name: "template-1",
  },
]
export const AccessApi = [
  {
    id: 1,
    access: "Access Api",
    title: "jacket",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
  {
    id: 2,
    access: "Access Api",
    title: "Marriage",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
  {
    id: 3,
    access: "Access Api",
    title: "Cooking",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
  {
    id: 5,
    access: "Access Api",
    title: "Cooking",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
  {
    id: 6,
    access: "Access Api",
    title: "Cooking",
    limit: "NA",
    time: "May 22 2024 | 12:59 PM",
  },
]
export const reports = [
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
  {
    id: 1,
    title: "heading 1",
    seen: 20,
    send: 25,
    recieved: 30,
  },
]

export const senderReport = [
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },

  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },

  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
  {
    id: 1,
    name: "jon deo",
    created_by: "smith",
  },
]

export const Chat = [
  {
    id: 1,
    type: "option",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },
  {
    id: 1,
    type: "option",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },
  {
    id: 1,
    type: "option",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },

  {
    id: 1,
    type: "chat",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },
  {
    id: 1,
    type: "chat",
    question: "Hello! How can I help you? , today",
    answer: "Hello,i’m fine,how can i help you? fine,how can i help you?",
  },
]

export const ButtonType = [
  {
    type: "quick",
    fields: [
      {
        id: 1,
        line1: "Marketing Opt-Out",
        line2: "recommend",
        btnIcon: <BsFillReplyFill />,
        btnTitle: "Quick Reply",
      },
      {
        id: 2,
        line1: "Custom",
        line2: "",
        btnIcon: <BsFillReplyFill />,
        btnTitle: "Quick Reply",
      },
    ],
  },
  {
    type: "action",
    fields: [
      {
        line1: "Visit Website",
        line2: "2 button maximum",
        btnIcon: <PiArrowSquareInLight />,
        btnTitle: "Visit Website",
      },
      {
        line1: "Call Phone Number",
        line2: "1 button maximum",
        btnIcon: <MdCall />,
        btnTitle: "Call Phone Number",
      },
      {
        line1: "Complete Flow",
        line2: "1 button maximum",
        btnIcon: "",
        btnTitle: "Complete Flow",
      },
      {
        line1: "Copy Offer Code",
        line2: "1 button maximum",
        btnIcon: <MdOutlineContentCopy />,
        btnTitle: "Copy Offer Code",
      },
    ],
  },
]

export const ActionBtnOption = [
  {
    id: 1,
    option: "Call Phone Number",
  },
  {
    id: 2,
    option: "Copy Offer Code",
  },
  {
    id: 3,
    option: "Visit Website",
  },
  {
    id: 4,
    option: "Complete Flow",
  },
]

export const InputFormat = [
  {
    id: 1,
    option: "None",
    icon: null,
  },
  {
    id: 2,
    option: "Text",
    icon: <FaTextHeight />,
  },
  {
    id: 3,
    option: "Image",
    icon: <FaImage />,
  },
  {
    id: 4,
    option: "Video",
    icon: <FaVideo />,
  },
  {
    id: 5,
    option: "Document",
    icon: <FaFileAlt />,
  },
  {
    id: 6,
    option: "Location",
    icon: <FaMapMarkerAlt />,
  },
]

export const chatDetail = [
  {
    id: 1,
    number: "+91 45674 23213",
    img: Img1,
    msg: "What time are we there?",
    time: "9:12 AM",
    notify: "1",
    messages: [
      {
        type: "question",
        content: "What time are we there?",
        time: "9:12 AM",
      },
      {
        type: "response",
        content: "We should be there by 9:30 AM.",
        time: "9:15 AM",
      },
      {
        type: "question",
        content: "Is there atmosphere rainy?",
        time: "9:12 AM",
      },
      {
        type: "response",
        content: "yes, Like cats and dogs...",
        time: "9:15 AM",
      },
    ],
  },
  {
    id: 2,
    number: "+91 98765 43210",
    img: Img2,
    msg: "See you soon!",
    time: "8:45 AM",
    notify: "2",
    messages: [
      {
        type: "question",
        content: "What time are we there?",
        time: "9:12 AM",
      },
      {
        type: "response",
        content: "We should be there by 9:30 AM.",
        time: "9:15 AM",
      },
    ],
  },
  {
    id: 3,
    number: "+91 12345 67890",
    img: Img1,
    msg: "Meeting rescheduled to tomorrow.",
    time: "11:30 AM",
    notify: "3",
    messages: [
      {
        type: "question",
        content: "Meeting rescheduled to tomorrow.",
        time: "11:30 AM",
      },
      {
        type: "response",
        content: "Got it, thanks for the update!",
        time: "11:32 AM",
      },
    ],
  },
  {
    id: 4,
    number: "+91 34567 12345",
    img: Img2,
    msg: "Please send the files.",
    time: "10:15 AM",
    notify: "4",
  },
  {
    id: 5,
    number: "+91 56789 12345",
    img: Img1,
    msg: "Lunch at 1 PM?",
    time: "12:05 PM",
    notify: "5",
  },
  {
    id: 6,
    number: "+91 65432 98765",
    img: Img2,
    msg: "Got it, thanks!",
    time: "6:45 AM",
    notify: "6",
    messages: [
      {
        type: "question",
        content: "Meeting rescheduled to tomorrow.",
        time: "11:30 AM",
      },
      {
        type: "response",
        content: "Got it, thanks for the update!",
        time: "11:32 AM",
      },
    ],
  },
  {
    id: 7,
    number: "+91 23456 78901",
    img: Img1,
    msg: "Let me know once you're free.",
    time: "7:10 AM",
    notify: "7",
    messages: [
      {
        type: "question",
        content: "Please send the files.",
        time: "10:15 AM",
      },
      {
        type: "response",
        content: "I'll send them shortly.",
        time: "10:16 AM",
      },
    ],
  },
  {
    id: 8,
    number: "+91 87654 32109",
    img: Img2,
    msg: "Happy Birthday!",
    time: "9:30 AM",
    notify: "8",
    messages: [
      {
        type: "question",
        content: "Please send the files.",
        time: "10:15 AM",
      },
      {
        type: "response",
        content: "I'll send them shortly.",
        time: "10:16 AM",
      },
    ],
  },
  {
    id: 9,
    number: "+91 65432 34567",
    img: Img1,
    msg: "Can we catch up later?",
    time: "3:20 PM",
    notify: "9",
    messages: [
      {
        type: "question",
        content: "Please send the files.",
        time: "10:15 AM",
      },
      {
        type: "response",
        content: "I'll send them shortly.",
        time: "10:16 AM",
      },
    ],
  },
  {
    id: 10,
    number: "+91 32109 87654",
    img: Img2,
    msg: "I am almost there.",
    time: "11:00 AM",
    notify: "10",
  },
  {
    id: 11,
    number: "+91 90876 54321",
    img: Img1,
    msg: "Check your email.",
    time: "2:15 PM",
    notify: "11",
    messages: [
      {
        type: "question",
        content: "Please send the files.",
        time: "10:15 AM",
      },
      {
        type: "response",
        content: "I'll send them shortly.",
        time: "10:16 AM",
      },
    ],
  },
  {
    id: 12,
    number: "+91 78654 32109",
    img: Img2,
    msg: "I'll send it over in a few minutes.",
    time: "5:30 PM",
    notify: "12",
    messages: [
      {
        type: "question",
        content: "Meeting rescheduled to tomorrow.",
        time: "11:30 AM",
      },
      {
        type: "response",
        content: "Got it, thanks for the update!",
        time: "11:32 AM",
      },
    ],
  },
]
