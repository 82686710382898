import React from "react"
import ChatList from "./ChatList.jsx"
import Chat from "./Chat.jsx"

const ChatIndex = () => {
  return (
    <div className="flex">
      <ChatList />
      <Chat />
    </div>
  )
}

export default ChatIndex
