import { useDispatch } from "react-redux"
import Topbar from "../../appbar/Topbar.jsx"
import RecentTemplates from "./RecentTemplates.jsx"
import TemplateCards from "./TemplateCards.jsx"
import { useUIContext } from "../../context/BasicProvider.jsx"
import { useTemplateMaster } from "../../redux/template/templateReducer.jsx"
import { useEffect } from "react"
import { FETCH_TEMPLATE_REQUEST } from "../../redux/template/templateAction.jsx"
import Loader from "../global/Loader.jsx"

const TemplateIndex = () => {
  const dispatch = useDispatch()
  const { selectWabaid, setLoading, loading } = useUIContext()
  const { templateData } = useTemplateMaster()

  useEffect(() => {
    if (selectWabaid) {
      dispatch({ type: FETCH_TEMPLATE_REQUEST, payload: selectWabaid })
      setLoading(false)
    }
  }, [selectWabaid])

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="px-4 sm:px-8 pt-4 sm:pt-5 flex flex-col gap-6">
        <Topbar title="Templates" />
        <div className="grid grid-cols-1 sm:grid-cols-5 gap-6">
          <div className=" sm:col-span-4 overflow-hidden hover:overflow-auto  2xl:h-[87vh] xl:h-[85vh] lg:h-[82vh]">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8">
              {templateData?.data?.map((template, index) => (
                <TemplateCards template={template} key={index} />
              ))}
            </div>
          </div>
          <div className="overflow-hidden hover:overflow-auto bg-white-100 flex flex-col border gap-5 2xl:p-3 xl:p-2 lg:p-2 border-gray-200 rounded-lg  2xl:h-[87vh] xl:h-[85vh] lg:h-[82vh]">
            <RecentTemplates />
          </div>
        </div>
      </div>
    </>
  )
}

export default TemplateIndex
