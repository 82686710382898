import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./App.css"
import Main from "./Main.jsx"
import TemplateIndex from "./component/template/TemplateIndex.jsx"
import Login from "./auth/Login.jsx"
import SenderReportIndex from "./component/senderreport/SenderReportIndex.jsx"
import DashboardIndex from "./component/Dashboard/DashboardIndex.jsx"
import Home from "./component/staticHome/Home.jsx"
import ContactUs from "./component/staticHome/ContactUs.jsx"
import Layout from "./component/staticHome/layout.jsx"
import Summary from "./component/senderreport/Summary.jsx"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-datepicker/dist/react-datepicker.css"
import Register from "./auth/Register.jsx"
import ChatIndex from "./component/chats/ChatIndex.jsx"
import ModifyTempIndex from "./component/modifyTemplate/ModifyTempIndex.jsx"

function App() {
  return (
    <div className="bg-white-500 h-screen">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            {" "}
            <Route index element={<Home />} />{" "}
            <Route path="/Contact" element={<ContactUs />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<DashboardIndex />} />
          <Route path="/template" element={<Main component={TemplateIndex} />} />
          <Route
            path="/reports/:id"
            element={<Main component={SenderReportIndex} />}
          />
          <Route
            path="/summary/:params/:id"
            element={<Main component={Summary} />}
          />
          <Route
            path="/reports/:id"
            element={<Main component={SenderReportIndex} />}
          />
          <Route
            path="/modifytemplate"
            element={<Main component={ModifyTempIndex} />}
          />
          <Route path="/chats" element={<Main component={ChatIndex} />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default App
