export const FETCH_REPORT_REQUEST = "FETCH_REPORT_REQUEST"
export const REPORT_DATA = "REPORT_DATA"

export const UPLOAD_FILE = "UPLOAD_FILE"

export const FETCH_ALL_FILES = "FETCH_ALL_FILES"
export const FILES_DATA = "FILES_DATA"

export const SEND_BULK_MESSAGE = "SEND_BULK_MESSAGE"

export const FETCH_IDTEMPLATE = "FETCH_IDTEMPLATE"
export const ID_TEMPLATE_DATA = "ID_TEMPLATE_DATA"

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST"

export const FETCH_SUMMARY_DATA = "FETCH_SUMMARY_DATA"
export const SUMMARY_DATA = "SUMMARY_DATA"
export const SUMMARY_DATA_ERROR = "SUMMARY_DATA_ERROR"

export const DOWNLOAD_SUMMARY_PDF = "DOWNLOAD_SUMMARY_PDF"
