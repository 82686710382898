import React from "react"
import { IoClose } from "react-icons/io5"

const CreateAppModal = ({ isOpen, onClose }) => {
  return (
    <div className={`popup-modal  ${isOpen ? "flex" : "hidden"}`}>
      <div className="bg-white-100 max-h-full w-3/12 flex flex-col gap-5 p-4">
        <div className="flex justify-between items-center text-primary-100 text-2xl font-medium">
          <p>App Type: Access Api</p>
          <IoClose onClick={onClose} className="cursor-pointer" />
        </div>
        <div className="flex flex-col gap-6">
          <div className="text-xs text-gray-400">
            Enter the unique app name associated with your whatsapp account
          </div>
          <div className="w-full">
            <form>
              <label
                htmlFor="additional-info"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Enter App Name:
              </label>
              <input
                type="text"
                className="border border-gray-300 p-2 rounded w-full"
                placeholder="App Name Required"
              />
            </form>
          </div>

          <button className="bg-primary-100 text-white-100 py-2 rounded-md">
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateAppModal
