// src/components/SearchField.jsx
import React from "react"
import { FiPaperclip } from "react-icons/fi"
import { CiFaceSmile } from "react-icons/ci"

const TypingField = () => {
  return (
    <div className="sticky bottom-0 p-4 flex items-center justify-center z-10">
      <div className="relative w-[80%]">
        <span className="absolute inset-y-0 left-3 flex items-center text-gray-500 bg-transparent">
          <CiFaceSmile className="w-6 h-6" />
        </span>

        <span className="absolute inset-y-0 right-3 flex items-center text-gray-500">
          <FiPaperclip className="w-5 h-5" />
        </span>

        <input
          type="text"
          placeholder="Type a message"
          className="w-full h-[2.6rem] bg-white-100 pl-10 pr-10 py-2 shadow-lg rounded-full focus:outline-none bg-gray-100"
        />
      </div>
    </div>
  )
}

export default TypingField
