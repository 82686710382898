import React from "react"
// import { RiCheckDoubleFill, RiCheckFill } from "react-icons/ri"
import { RiCheckFill } from "react-icons/ri"

const ChatMessages = ({ chatData }) => {
  return (
    <div className="flex-grow p-3 overflow-y-auto">
      {chatData.messages &&
        chatData.messages.map((message, idx) => (
          <div
            key={idx}
            className={`flex flex-col mb-4 ${
              message.type === "question" ? "items-start" : "items-end"
            }`}
          >
            <div className="p-3 px-6 bg-grey-400 rounded-t-[1.2rem] rounded-br-[1.2rem] max-w-[60%] relative break-words">
              {message.content}
              {message.type === "response" && (
                <RiCheckFill className="absolute bottom-1 right-2 text-primary-100" />
                // <RiCheckDoubleFill className="absolute bottom-1 right-2 text-primary-100" />
              )}
            </div>
            <div className="text-gray-400 text-xs mt-1">{message.time}</div>
          </div>
        ))}
    </div>
  )
}

export default ChatMessages
