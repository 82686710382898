import React, { useEffect } from "react"
import { FiFileText } from "react-icons/fi"
import { useDispatch } from "react-redux"
import {
  FETCH_RECENTTEMPLATE_REQUEST,
  RECENT_TEMPLATE_DELETE,
} from "../../redux/template/templateAction.jsx"
import { useTemplateMaster } from "../../redux/template/templateReducer.jsx"
import { TruncateChar } from "../../utils/Truncate.jsx"
import Tooltip from "../../utils/ToolTip.jsx"
import { Link } from "react-router-dom"
import { useUIContext } from "../../context/BasicProvider.jsx"
import { FiTrash } from "react-icons/fi"

function RecentTemplates() {
  const dispatch = useDispatch()
  const { recentTemplateData } = useTemplateMaster()
  const { selectWabaid, setLoading } = useUIContext()

  useEffect(() => {
    if (selectWabaid) {
      dispatch({ type: FETCH_RECENTTEMPLATE_REQUEST, payload: selectWabaid })
      setLoading(false)
    }
  }, [selectWabaid, dispatch, setLoading])
  const deleteRecentTemplate = (id) => {
    dispatch({ type: RECENT_TEMPLATE_DELETE, payload: id })
  }
  return (
    <>
      <p className="2xl:text-xl xl:text-lg lg:text-sm font-medium leading-normal text-black-100">
        Recent Templates
      </p>
      <div className="flex flex-col gap-2">
        {recentTemplateData?.data?.length > 0 ? (
          recentTemplateData.data.map((value, index) => (
            <Tooltip text={value?.template_name} key={index}>
              <div className="flex justify-between items-center gap-2 2xl:p-2 lg:p-2 rounded-md bg-white-500 shadow-sm border border-black-200 relative group">
                <Link to={`/summary/template_history_id/${value.id}`}>
                  <div className="flex gap-4">
                    <FiFileText className="text-orange-100 font-bold 2xl:text-xl xl:text-lg lg:text-sm" />
                    <span className="capitalize">
                      {value?.template_name === null
                        ? "-"
                        : value.template_name &&
                          TruncateChar(value.template_name, 10)}
                    </span>
                  </div>
                </Link>

                <button
                  className="relative cursor-pointer flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                  onClick={() => deleteRecentTemplate(value?.id)}
                >
                  <div className="bg-red-200 text-red-300 text-lg p-2 rounded-full">
                    <FiTrash />
                  </div>
                </button>
              </div>
            </Tooltip>
          ))
        ) : (
          <>
            <hr />
            <p className="2xl:text-lg xl:text-lg lg:text-sm text-center">
              No data found
            </p>
          </>
        )}
      </div>
    </>
  )
}

export default RecentTemplates
