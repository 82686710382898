import React, { useState } from "react"
import { IoClose } from "react-icons/io5"
import { useDispatch } from "react-redux"
import { SEND_BULK_MESSAGE } from "../../redux/report/reportAction.jsx"
import { useParams } from "react-router-dom"
import { useUIContext } from "../../context/BasicProvider.jsx"

const SendMessage = ({ open, handleClose, selectedItems, setSelectedItems }) => {
  const [sendData, setSendData] = useState({
    name: "",
    description: "",
  })
  const { id } = useParams()
  const { selectWabaid, businessid } = useUIContext()
  const dispatch = useDispatch()
  const handleChange = (e) => {
    setSendData({
      ...sendData,
      [e.target.name]: e.target.value,
    })
  }
  const sendBulkMessage = () => {
    dispatch({
      type: SEND_BULK_MESSAGE,
      payload: {
        template_id: id,
        business_id: businessid,
        waba_id: selectWabaid,
        name: sendData.name,
        description: sendData.description,
        sheet_id: selectedItems,
      },
    })
    setSendData({})
    setSelectedItems([])
    handleClose()
  }
  return (
    <>
      <div className={`popup-modal  ${open ? "flex" : "hidden"}`}>
        <div className="bg-white-100 max-h-full w-3/12 flex flex-col gap-5 p-4">
          <div className="flex justify-between items-center text-primary-100 text-2xl font-medium">
            <div>Share</div>
            <IoClose onClick={handleClose} className="cursor-pointer" />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              className="bg-gray-50 border border-gray-300 text-gray-900  text-sm rounded-lg block w-full p-2.5"
              placeholder="Name"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Description
            </label>
            <textarea
              rows="4"
              id="description"
              name="description"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
              placeholder="Description"
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-end ">
            <button
              className="bg-primary-100 text-white-100 rounded px-4 py-2"
              onClick={sendBulkMessage}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SendMessage
