import React from "react"
import TypingField from "./TypingField.jsx"
import ChatMessages from "./ChatMessages.jsx"
import { useUIContext } from "../../context/BasicProvider.jsx"

const Chat = () => {
  const { chatData } = useUIContext()

  return (
    <div className="w-[75%] h-[100vh] flex flex-col">
      <div className="sticky top-0 flex border-b h-[4.5rem] p-3 gap-2 items-center z-10">
        <img
          src={chatData.img}
          alt="profile"
          className="w-[3rem] h-[3rem] rounded-full"
        />
        <div className="font-bold">{chatData.number}</div>
      </div>

      <ChatMessages chatData={chatData} />

      <TypingField />
    </div>
  )
}

export default Chat
