import React from "react"
import TitleHeader from "../component/global/TitleHeader.jsx"
import UserProfile from "../component/global/UserProfile.jsx"
import { useLocation } from "react-router-dom"

const Topbar = ({ title }) => {
  const { pathname } = useLocation()

  return (
    <div className="flex justify-between items-center flex-wrap gap-3">
      <TitleHeader title={title} />
      {pathname !== "/chats" && <UserProfile />}
    </div>
  )
}

export default Topbar
