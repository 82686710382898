import React, { useEffect, useRef, useState } from "react"
import { FaRegCalendarAlt } from "react-icons/fa"
import { MdOutlineFileDownload } from "react-icons/md"
import { MdOutlineFileUpload } from "react-icons/md"
import { ImFileText2, ImFileExcel } from "react-icons/im"
import { AiOutlineEye } from "react-icons/ai"
import { FaFilePdf } from "react-icons/fa6"
import { FiSend } from "react-icons/fi"
import { useDispatch } from "react-redux"
import {
  DOWNLOAD_SUMMARY_PDF,
  FETCH_REPORT_REQUEST,
} from "../../redux/report/reportAction.jsx"
import { Link, useParams } from "react-router-dom"
import { useReportMaster } from "../../redux/report/reportReducer.jsx"
import moment from "moment"
import { BsDashLg } from "react-icons/bs"

export const ReportCards = ({ value }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const dispatch = useDispatch()
  const hnadleDownloadSummary = (id, params) => {
    dispatch({ type: DOWNLOAD_SUMMARY_PDF, payload: id, params: params })
    setMenuOpen(false)
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false)
      }
    }
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])
  return (
    <>
      <div className="bg-white-500 rounded-md border border-black-200 flex flex-col gap-5">
        <div className="flex justify-between items-center p-5">
          <div>
            <p className="text-primary-100 font-bold text-xl">{value?.name}</p>
            <p className="text-[#363636]">
              {moment(value?.created_at).format("Do MMM YYYY")}
            </p>
          </div>
          <div className="flex justify-between gap-3 cursor-pointer">
            <Link to={`/summary/recode_id/${value.id}`}>
              <div className="rounded-full bg-orange-100 text-white-100 p-2 text-xl">
                <ImFileText2 />
              </div>
            </Link>
            <div
              className="rounded-full bg-orange-100 text-white-100 p-2 text-xl"
              ref={menuRef}
            >
              <MdOutlineFileDownload onClick={() => setMenuOpen(!menuOpen)} />
            </div>
            {menuOpen && (
              <div
                className="absolute mt-10 w-32 rounded-md shadow-lg bg-white-100 divide-y divide-gray-100"
                style={{ zIndex: 9999 }}
              >
                <div className="py-1">
                  <button
                    className="flex gap-3 items-center px-4 py-2 text-sm text-primary-100 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => hnadleDownloadSummary(value?.id, "pdf")}
                  >
                    Download <FaFilePdf />
                  </button>
                  <button
                    className="flex gap-3 items-center px-4 py-2 text-sm text-primary-100 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => hnadleDownloadSummary(value?.id, "xls")}
                  >
                    Download <ImFileExcel />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <hr />
          <div className="rounded-md p-3 flex justify-around items-center text-icon_color-100 text-xl font-semibold ">
            <div className="flex items-center gap-1.5">
              <AiOutlineEye />
              {value?.seen}
            </div>
            <div className="flex items-center gap-1.5">
              <FiSend />
              {value?.send}
            </div>
            <div className="flex items-center gap-1.5">
              <MdOutlineFileUpload />
              {value?.delivered}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CustomDatePicker = ({ selectedDate, onDateChange }) => {
  return (
    <div className="relative inline-block w-[8rem] border">
      <div className="border border-gray-300 text-gray-700 rounded p-1 text-xs ">
        <input
          type="date"
          value={moment(selectedDate).format("YYYY-MM-DD")}
          onChange={(e) => onDateChange(e.target.value)}
        />
      </div>
    </div>
  )
}

const CustomDropdown = ({
  isOpen,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  return (
    <div
      className={`absolute right-0 z-50  md:w-40 ${isOpen ? "flex" : "hidden"}`}
      style={{ marginTop: "-2rem" }}
    >
      <div className="bg-white-100 border shadow-md rounded-md p-3 ">
        <div className="flex gap-4 mb-4">
          <div>
            <div className="text-xs">From</div>
            <CustomDatePicker selectedDate={startDate} onDateChange={setStartDate} />
          </div>
          <div>
            <div className="text-xs">To</div>
            <CustomDatePicker selectedDate={endDate} onDateChange={setEndDate} />
          </div>
        </div>
        <div className="flex flex-col gap-2 text-xs">
          <button className="relative overflow-hidden text-primary-100 bg-white-100 border border-gray-200 rounded-md py-2 hover:text-white-100 hover:bg-gradient-to-r hover:from-primary-100 hover:to-primary-600 hover:via-white transition-all duration-300">
            Today
          </button>
          <button className="relative overflow-hidden text-primary-100 bg-white-100 border border-gray-200 rounded-md py-2 hover:text-white-100 hover:bg-gradient-to-r hover:from-primary-100 hover:to-primary-600 hover:via-white transition-all duration-300">
            Past 3 months
          </button>
          <button className="relative overflow-hidden text-primary-100 bg-white-100 border border-gray-200 rounded-md py-2 hover:text-white-100 hover:bg-gradient-to-r hover:from-primary-100 hover:to-primary-600 hover:via-white transition-all duration-300">
            This month
          </button>
          <button className="relative overflow-hidden text-primary-100 bg-white-100 border border-gray-200 rounded-md py-2 hover:text-white-100 hover:bg-gradient-to-r hover:from-primary-100 hover:to-primary-600 hover:via-white transition-all duration-300">
            Past month
          </button>
        </div>
      </div>
    </div>
  )
}

const Report = () => {
  const dispatch = useDispatch()
  const { reportData } = useReportMaster()
  const { id } = useParams()
  const [showDropdown, setShowDropdown] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    dispatch({ type: FETCH_REPORT_REQUEST, payload: id })
  }, [dispatch, id])

  return (
    <>
      <div className="flex justify-between items-center p-0">
        <p className="text-2xl font-medium text-black-100">Reports</p>
        <div className="flex items-center gap-2 rounded-md text-sm bg-white-100">
          <p className="flex items-center">
            {moment(startDate).format("D MMM YYYY")}
          </p>
          <BsDashLg className="text-primary-100" />
          <p className="flex items-center">{moment(endDate).format("D MMM YYYY")}</p>
          <FaRegCalendarAlt
            onClick={() => setShowDropdown(!showDropdown)}
            className="text-xl cursor-pointer text-primary-100 ml-2"
          />
        </div>
      </div>
      <hr />
      <div className="relative">
        <CustomDropdown
          isOpen={showDropdown}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
      <div className="hover:overflow-auto overflow-hidden h-[74vh] flex flex-col gap-5">
        {reportData?.data?.length > 0 ? (
          reportData?.data?.map((value, index) => (
            <ReportCards key={index} value={value} />
          ))
        ) : (
          <>
            <p className="text-center">No Reports</p>
          </>
        )}
      </div>
    </>
  )
}

export default Report
