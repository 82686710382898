import React, { useEffect } from "react"
import { useUIContext } from "../../context/BasicProvider.jsx"
import { useNavigate } from "react-router-dom"
import Select from "react-select"

const UserProfile = () => {
  const {
    setSelectWabaid,
    setBussinessid,
    setLoading,
    setWabaidlist,
    wabaidlist,
    selectWabaid,
  } = useUIContext()

  const navigate = useNavigate()

  useEffect(() => {
    const wabaList = JSON.parse(sessionStorage.getItem("wabalist"))
    const options = (wabaList?.data || []).map((item) => ({
      value: item.waba_id,
      label: item.waba_id,
      business_id: item.business_id,
    }))
    setWabaidlist(options)

    const savedWabaid = sessionStorage.getItem("wabaid")
    const defaultOption =
      options.find((option) => option.value === savedWabaid) || options[0]

    if (defaultOption) {
      setSelectWabaid(defaultOption.value)
      setBussinessid(defaultOption.business_id)
      sessionStorage.setItem("wabaid", defaultOption.value)
      sessionStorage.setItem("businessid", defaultOption.business_id)
    }
  }, [setWabaidlist, setSelectWabaid, setBussinessid])

  const handleChange = (option) => {
    setSelectWabaid(option.value)
    setBussinessid(option.business_id)
    sessionStorage.setItem("wabaid", option.value)
    sessionStorage.setItem("businessid", option.business_id)
    setLoading(true)
    navigate("/template")
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 190,
      borderRadius: "8px",
      boxShadow: "none",
      border: "none",
      "&:hover": {
        border: "none",
      },
      "&:focus": {
        border: "none",
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      marginTop: 0,
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4C426D" : "white",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  }

  return (
    <div className="flex flex-row items-center gap-1 2xl:px-5 xl:px-3 lg:px-2 px-4 text-black-100 bg-white-100 shadow-md rounded-lg">
      <img
        className="2xl:w-10 2xl:h-10 xl:w-9 xl:h-9 lg:w-9 lg:h-9 md:w-9 md:h-9 w-9 h-9 rounded-full"
        src="https://static.vecteezy.com/system/resources/previews/002/079/984/original/phone-icon-flat-style-isolated-on-grey-background-telephone-symbol-call-illustration-sign-for-web-and-mobile-app-free-vector.jpg"
        alt="Rounded avatar"
      />
      <Select
        options={Array.isArray(wabaidlist) ? wabaidlist : []}
        value={
          Array.isArray(wabaidlist)
            ? wabaidlist.find((option) => option.value === selectWabaid)
            : null
        }
        onChange={handleChange}
        styles={customStyles}
        className="text-gray-900 text-sm block w-full p-2 rounded-lg bg-white border-none"
      />
    </div>
  )
}

export default UserProfile
